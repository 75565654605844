#home { 
    position: relative; 
    background-color: var(--primary-color);
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper { 
        padding: 0; 

        .copyright { 
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 0rem 2rem 0rem; 

    @media screen and (min-width: 2000px) {
        padding-top: 0rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 800px) {
        padding: 0rem 1rem 2rem;
    }
}

.app__header-info { 
    flex: 0.65; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    
    // margin: 0 2 rem;

    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge { 
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding: 10rem 2rem 0rem; 

    .badge-cmp { 
        padding: 1.5rem 2rem;
        border: var(--white-color); 
        border-radius: 15px;
        flex-direction: row;
        width: 100%;

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        
        h1 { 
            letter-spacing: 0.10em;
            text-align: center;
        }

        p { 
            text-align: center;
        }
    }

    span { 
        font-size: 2.5rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (min-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20rem 2rem 0rem; 
    }
}

.app__header-img {
    flex: 1;
    height: 50%;
    display: flex;
    justify-content: center;
    position: relative;

    
    img {
      width: 70%;
      object-fit: contain;
      z-index: 1;
      border-radius: 10px;
    }

    // @media screen and (max-width: 1200px) {
    //     margin: 2rem 0; 
  
    // }
}

.app__header-see { 
    margin: 3rem 12rem 4rem;

    @media screen and (max-width: 1600px) {
        margin: 3rem 4rem 4rem;
    }

    @media screen and (min-width: 1600px) {
        margin: 5rem 10rem 4rem;
    }
}

a {
  text-decoration: none;
  color: inherit
}

.button-see { 
    position: relative;
    margin: auto;
    padding: 19px 22px;
    transition: all 0.2s ease;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 28px;
    background: rgba(1, 93, 103, 0.8);
    width: 56px;
    height: 56px;
    transition: all 0.3s ease;
  }

    span { 
        position: relative;
        font-size: 1rem;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        // text-transform: uppercase;
        vertical-align: middle;

        @media screen and (max-width: 500px) {
            font-size: 0.9rem;
        }
    
    }

    svg { 
        position: relative;
        top: 0;
        margin-left: 10px;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--secondary-color);
        stroke-width: 2;
        transform: translateX(-5px);
        transition: all .3s ease;
    }

    &:hover {
    &:before {
      width: 100%;
      background: rgba(1, 93, 103, 0.5);
    }
}
}



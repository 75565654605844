@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;800&display=swap');;

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #f0fff1;
  --secondary-color: #015D67;
  --black-color: #2a2a2a;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}